.text-section {
  margin-left: 5%;
  margin-right: 5%;
}

.remove-btn {
  background: none;
  color: red;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-left: 10px;
}

.remove-btn:hover {
  text-decoration: underline;
}
