.bold-header {
  font-weight: bold;
}

.text-section {
  margin-left: 5%;
  margin-right: 5%;
}

.warning-text {
  color: red;
}
