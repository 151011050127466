/* Define variables for dark mode */
:root {
  --navbar-background-color: #2b2b2b7f;
  --navbar-link-color: white;
  --navbar-glow-color: white;
  --contrast-color: #555555;
}

.flashcard {
  width: 300px;
  height: 350px;
  border: 0.2rem solid var(--contrast-color);
  perspective: 1000px;
  cursor: pointer;
}

.flashcard-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flashcard-front,
.flashcard-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 20px;
}

.flashcard-back {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.flipped {
  transform: rotateY(180deg);
  backface-visibility: hidden;
}

.flashcard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Assuming you want to center it in the viewport vertically as well */
}

.next-button {
  font-size: 1.2rem;
}

.next-button:hover {
  /* empty for now */
}

.text-section {
  margin-left: 5%;
  margin-right: 5%;
}
