/* Define variables for dark mode */
:root {
  --navbar-background-color: #2b2b2b7f;
  --navbar-link-color: white;
  --navbar-glow-color: white;
  --contrast-color: #555555;
}

ul {
  list-style: none;
}

/* Navbar styling */
.navbar-header {
  display: flex;
  margin-bottom: 5rem;
  align-items: center;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--navbar-background-color);
  width: 100%;
  height: 4.2rem;
  position: fixed;
  top: 0;
  padding: 0 2rem;
  border-bottom: 0.1rem solid var(--contrast-color);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.navbar a {
  color: var(--navbar-link-color);
  font-size: 1.2rem;
  margin: 0 2rem;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
  opacity: 1;
}

.navbar a:hover {
  text-shadow: var(--navbar-glow-color) 0 0 0.5rem;
  /*border-bottom: 0.1rem solid var(--navbar-link-color);*/
  text-decoration: underline;
}

.navbar ul {
  display: flex;
  border: 0rem;
  margin: 0rem;
  padding: 0rem;
}

.navbar span {
  font-weight: 600;
}

.upload-link-button {
  background: none;
  color: var(--navbar-link-color);
  border: none;
  padding: 0;
  font-size: 1.2rem;
  margin: 0 2rem;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  display: inline;
  outline: none;
  transition: 0.3s;
  border-bottom: 0.1rem solid transparent; /* Add this to align with <a> hover effect */
}

.upload-link-button:hover {
  text-shadow: var(--navbar-glow-color) 0 0 0.5rem;
  text-decoration: underline;
  /*border-bottom: 0.1rem solid var(--navbar-link-color);*/ /* Ensure this matches the <a> hover effect */
}
