:root {
  --background-color-1: #2b2b2b;
  --background-color-2: #202020;
  --text-color: white;
  --link-color: #00a800;
  --footer-color: #2b2b2b;
  --footer-text-color: white;
  --contrast-color: #555555;
}

body {
  font-family: sans-serif;
  color: var(--text-color);
  background-color: var(--background-color-1);
}
